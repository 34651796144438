import {ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, signal, WritableSignal} from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {filter, map, Observable, Subscription, take, tap} from 'rxjs';
import {Store} from '@ngrx/store';
import {logout} from '@core/store/actions/core.actions';
import {IUser} from '@core/models/interfaces/user.interface';
import {selectUser} from '@core/store/selectors/core.selectors';
import {ModalViewerService} from '@nesea/ngx-ui-kit/modal';
import {ConfirmModalComponent, IConfirmModalOutput} from '@shared/components/confirm-modal/confirm-modal.component';
import {ProfileModalComponent} from '@core/modals/profile-modal/profile-modal.component';
import {ConfigService} from "@core/services/config.service";

@Component({
  selector: 'nsf-main-container',
  templateUrl: './main-container.component.html',
  styleUrl: './main-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainContainerComponent implements OnInit, OnDestroy {

  user$: Observable<IUser>;

  currentUrl: string;

  private _configService: ConfigService = inject(ConfigService);

  readonly environment: WritableSignal<string> = signal(this._configService.env);
  private _currentUrlSubscription: Subscription = new Subscription();

  constructor(
    private _store: Store,
    private _router: Router,
    private _modalViewerService: ModalViewerService
  ) { }

  get isLogin(): boolean {
    return this.currentUrl?.includes('login');
  }

  get isErrorPage(): boolean {
    return this.currentUrl?.includes('error-page');
  }

  ngOnInit(): void {
    this.user$ = this._store.select(selectUser);

    this._currentUrlSubscription = this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(event => (event as RouterEvent).url),
      tap(currentUrl => this.currentUrl = currentUrl)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._currentUrlSubscription.unsubscribe();
  }

  onBrandClick(): void {
    this._router.navigate(['']);
  }

  onProfileClick(user: IUser): void {
    this._modalViewerService.open(ProfileModalComponent, { size: 'lg', title: 'HEADER.PROFILE.MODAL.TITLE', data: { user } }).pipe(
      take(1)
    ).subscribe();
  }

  onLogout(): void {
    this._modalViewerService.open(ConfirmModalComponent, { size: 'md', title: 'MODAL.CONFIRM.TITLE', titleUppercase: true, data: { message: 'MODAL.LOGOUT.MESSAGE' } }).pipe(
      take(1),
      filter((output: IConfirmModalOutput) => !!output?.outcome),
      tap(() => this._store.dispatch(logout()))
    ).subscribe();
  }

}
