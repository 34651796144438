import * as i0 from '@angular/core';
import { InjectionToken, inject, Injectable, Inject, makeEnvironmentProviders, ElementRef, Directive, Input, HostListener, ChangeDetectorRef, Component, ChangeDetectionStrategy, ViewChild, NgModule, EventEmitter, Output, ApplicationRef, EnvironmentInjector, createComponent } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TRANSLATION_FORM_ERROR_PREFIX, SharedUtils, TranslationDateService } from '@nesea/ngx-ui-kit/shared';
import * as i2 from '@jsverse/transloco';
import { TRANSLOCO_MISSING_HANDLER, provideTransloco, translocoConfig, provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { provideTranslocoLocale, TranslocoLocaleModule } from '@jsverse/transloco-locale';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { BehaviorSubject, Subscription, tap, skipWhile, delay, filter, startWith } from 'rxjs';
import { ToastService } from '@nesea/ngx-ui-kit/toast';
import { Router, NavigationEnd } from '@angular/router';
import * as i1 from '@angular/common';
import { CommonModule, DOCUMENT } from '@angular/common';
import { autoUpdate, offset, arrow, flip, computePosition } from '@floating-ui/dom';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { ModalViewerService } from '@nesea/ngx-ui-kit/modal';
const _c0 = ["floatingElementRef"];
function TooltipContentComponent_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.tooltipConfig.data.tooltip, " ");
  }
}
function TooltipContentComponent_Conditional_5_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TooltipContentComponent_Conditional_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TooltipContentComponent_Conditional_5_ng_container_0_Template, 1, 0, "ng-container", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.tooltipConfig.data.tooltip);
  }
}
const _c1 = (a0, a1, a2, a3) => ({
  "bi-info-circle": a0,
  "bi-check-lg": a1,
  "bi-exclamation-triangle": a2,
  "bi-x-circle": a3
});
const _c2 = a0 => ({
  "d-none": a0
});
const _forTrack0 = ($index, $item) => $item.id;
function OverlayComponent_For_3_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function OverlayComponent_For_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtemplate(1, OverlayComponent_For_3_ng_container_1_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const modal_r1 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("id", ctx_r1.getModalId(modal_r1.id))("hidden", modal_r1.hidden);
    i0.ɵɵattribute("aria-labelledby", ctx_r1.getModalId(modal_r1.id) + "-title");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngComponentOutlet", modal_r1.component);
  }
}
function OverlayComponent_For_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "nsa-toast", 6);
    i0.ɵɵlistener("close", function OverlayComponent_For_6_Template_nsa_toast_close_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onCloseToast($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const toastMessage_r4 = ctx.$implicit;
    i0.ɵɵproperty("messageConfig", toastMessage_r4);
  }
}
function OverlayComponent_For_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "nsa-tooltip-content", 4);
  }
  if (rf & 2) {
    const tooltip_r5 = ctx.$implicit;
    i0.ɵɵproperty("tooltipConfig", tooltip_r5);
  }
}
const defaultToastConfig = {
  timeOut: 5000,
  disableTimeOut: false,
  placement: 'top-end'
};
const TRANSLATION_PATH = new InjectionToken('translationPath');
class TranslationHttpLoaderService {
  constructor(_path) {
    this._path = _path;
    this._httpClient = inject(HttpClient);
  }
  getTranslation(lang) {
    return this._httpClient.get(`${this._path}${lang}.json`);
  }
  static {
    this.ɵfac = function TranslationHttpLoaderService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TranslationHttpLoaderService)(i0.ɵɵinject(TRANSLATION_PATH));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TranslationHttpLoaderService,
      factory: TranslationHttpLoaderService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TranslationHttpLoaderService, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [TRANSLATION_PATH]
    }]
  }], null);
})();
const defaultTranslationPath = '/assets/i18n/';
const defaultTranslationFormErrorPrefix = 'NSA.FORM.ERROR';
const defaultTranslationConfig = {
  defaultLang: 'it',
  availableLangs: ['it'],
  reRenderOnLangChange: true
};
const defaultTranslationLocaleConfig = {
  global: {},
  localeBased: {}
};
const defaultTranslationLocaleLangConfig = {
  'it': 'it-IT'
};
const defaultTranslationLocale = 'it-IT';
const defaultTranslationCurrency = 'EUR';
const defaultTranslationMessageFormatConfig = {
  locales: ['it-IT']
};
var IT = {
  "FORM": {
    "FILE": {
      "LABEL": "File",
      "PLACEHOLDER": "Nessun file selezionato",
      "VALUE": "{multiple, select, true{ {count, plural, =0{} one{ {{name}} } other{# file selezionati}} } other{ {{name}} }}",
      "SELECTION": "Scegli il file",
      "UPLOAD": "Carica il file"
    },
    "DATEPICKER": {
      "TODAY": "Oggi"
    },
    "ERROR": {
      "REQUIRED": "Il campo \u00E8 obbligatorio",
      "EMAIL": "L'email non \u00E8 corretta",
      "MIN": "Il campo deve essere maggiore di {min}",
      "MAX": "Il campo deve essere minore di {max}",
      "MINLENGTH": "Il campo deve avere una lunghezza maggiore di {requiredLength} caratteri",
      "MAXLENGTH": "Il campo deve avere una lunghezza minore di {requiredLength} caratteri",
      "PATTERN": "Il campo non \u00E8 valido",
      "TIMEPICKERSTEP": "L'orario immesso non \u00E8 valido",
      "TIMEPICKERMIN": "L'orario immesso deve essere maggiore delle {requiredTime}",
      "TIMEPICKERMAX": "L'orario immesso deve essere minore delle {requiredTime}",
      "TIMEPICKERSINGLERANGE": "L'orario immesso deve essere compreso nella fascia {minTime} - {maxTime}",
      "TIMEPICKERMULTIPLERANGE": "L'orario immesso deve essere compreso nelle fasce {timeSlots}",
      "FILEEXTENSION": "L'estensione non \u00E8 valida, sono ammessi solamente file con estensione {acceptedExtensions}",
      "FILESIZE": "Il file deve avere una dimensione massima di {maxSize}"
    }
  },
  "TABLE": {
    "ACTIONS": "Azioni",
    "EMPTY": "Nessun elemento",
    "TOTAL_ITEMS": "{totalItems} elementi totali"
  },
  "HEADER": {
    "HELLO": "Ciao",
    "PROFILE": "Profilo"
  },
  "ACTION": {
    "EXIT": "Esci",
    "CLOSE": "Chiudi",
    "CLEAR": "Cancella"
  }
};
const loader = ['it'].reduce((acc, lang) => {
  // TODO: Fix  Could not resolve import("./i18n/**/*.json") error
  // acc[lang] = () => import(`./i18n/${lang}.json`);
  acc[lang] = () => new Promise(resolve => {
    resolve(IT);
  });
  return acc;
}, {});
class MissingTranslationHandler {
  constructor(_missingHandlerFn) {
    this._missingHandlerFn = _missingHandlerFn;
  }
  handle(key, data, params) {
    return !!this._missingHandlerFn ? this._missingHandlerFn(key, data, params) : key;
  }
}
var LOCALE_CURRENCY = {
  'aa-DJ': 'DJF',
  'aa-ER': 'ERN',
  'aa-ET': 'ETB',
  'ab-GE': 'ETB',
  'ab-TR': 'ETB',
  'af-BW': 'ETB',
  'af-NA': 'ETB',
  'af-ZA': 'ZAR',
  'ak-GH': 'ZAR',
  'am-ET': 'ETB',
  'am-IL': 'ETB',
  'ar-AE': 'AED',
  'ar-AR': 'AED',
  'ar-BH': 'BHD',
  'ar-CM': 'BHD',
  'ar-CY': 'BHD',
  'ar-DJ': 'BHD',
  'ar-DZ': 'DZD',
  'ar-EG': 'EGP',
  'ar-EH': 'EGP',
  'ar-ER': 'EGP',
  'ar-IL': 'EGP',
  'ar-IQ': 'IQD',
  'ar-IR': 'IQD',
  'ar-JO': 'JOD',
  'ar-KE': 'JOD',
  'ar-KM': 'JOD',
  'ar-KW': 'KWD',
  'ar-LB': 'LBP',
  'ar-LY': 'LYD',
  'ar-MA': 'MAD',
  'ar-ML': 'MAD',
  'ar-MR': 'MAD',
  'ar-NE': 'MAD',
  'ar-NG': 'MAD',
  'ar-OM': 'OMR',
  'ar-PS': 'OMR',
  'ar-QA': 'QAR',
  'ar-SA': 'SAR',
  'ar-SD': 'SDD',
  'ar-SS': 'SDD',
  'ar-SY': 'SYP',
  'ar-TD': 'SYP',
  'ar-TJ': 'SYP',
  'ar-TN': 'TND',
  'ar-TR': 'TND',
  'ar-YE': 'YER',
  'as-IN': 'INR',
  'av-RU': 'INR',
  'ay-BO': 'INR',
  'ay-PE': 'INR',
  'az-AZ': 'AZM',
  'ba-RU': 'AZM',
  'be-BY': 'BYR',
  'be-PL': 'BYR',
  'be-UA': 'BYR',
  'bg-BG': 'BGN',
  'bg-GR': 'BGN',
  'bg-MD': 'BGN',
  'bg-RO': 'BGN',
  'bg-TR': 'BGN',
  'bg-UA': 'BGN',
  'bi-VU': 'BGN',
  'bm-ML': 'BGN',
  'bn-BD': 'BDT',
  'bn-GB': 'BDT',
  'bn-IN': 'INR',
  'bn-NP': 'INR',
  'bo-CN': 'CNY',
  'bo-IN': 'INR',
  'bo-NP': 'INR',
  'br-FR': 'EUR',
  'bs-BA': 'BAM',
  'ca-AD': 'EUR',
  'ca-ES': 'EUR',
  'ca-FR': 'EUR',
  'ca-IT': 'EUR',
  'ce-RU': 'EUR',
  'ch-GU': 'EUR',
  'ch-MP': 'EUR',
  'co-FR': 'EUR',
  'cs-CZ': 'CZK',
  'cv-RU': 'RUB',
  'cy-AR': 'RUB',
  'cy-GB': 'GBP',
  'da-DE': 'GBP',
  'da-DK': 'DKK',
  'da-GL': 'DKK',
  'da-IS': 'DKK',
  'de-AT': 'EUR',
  'de-BE': 'EUR',
  'de-BR': 'EUR',
  'de-CA': 'EUR',
  'de-CH': 'CHF',
  'de-CZ': 'CHF',
  'de-DE': 'EUR',
  'de-DK': 'EUR',
  'de-HU': 'EUR',
  'de-IT': 'EUR',
  'de-KZ': 'EUR',
  'de-LI': 'EUR',
  'de-LU': 'EUR',
  'de-NA': 'EUR',
  'de-PL': 'EUR',
  'de-PY': 'EUR',
  'de-RO': 'EUR',
  'de-SK': 'EUR',
  'de-US': 'EUR',
  'dv-IN': 'EUR',
  'dv-MV': 'MVR',
  'dz-BT': 'BTN',
  'dz-IN': 'BTN',
  'ee-GH': 'BTN',
  'ee-TG': 'BTN',
  'el-AL': 'BTN',
  'el-CY': 'EUR',
  'el-DE': 'EUR',
  'el-EG': 'EUR',
  'el-GB': 'EUR',
  'el-GR': 'EUR',
  'el-IT': 'EUR',
  'el-RO': 'EUR',
  'el-TR': 'EUR',
  'el-UA': 'EUR',
  'en-AC': 'EUR',
  'en-AG': 'XCD',
  'en-AI': 'XCD',
  'en-AS': 'XCD',
  'en-AU': 'AUD',
  'en-AW': 'AUD',
  'en-BB': 'AUD',
  'en-BE': 'AUD',
  'en-BM': 'AUD',
  'en-BN': 'AUD',
  'en-BS': 'AUD',
  'en-BW': 'BWP',
  'en-BZ': 'BWP',
  'en-CA': 'CAD',
  'en-CC': 'CAD',
  'en-CK': 'CAD',
  'en-CM': 'CAD',
  'en-CN': 'CAD',
  'en-CX': 'CAD',
  'en-DE': 'CAD',
  'en-DG': 'CAD',
  'en-DM': 'CAD',
  'en-DO': 'CAD',
  'en-ES': 'CAD',
  'en-ET': 'CAD',
  'en-FI': 'CAD',
  'en-FJ': 'CAD',
  'en-FK': 'CAD',
  'en-FM': 'CAD',
  'en-FR': 'CAD',
  'en-GB': 'GBP',
  'en-GD': 'GBP',
  'en-GG': 'GBP',
  'en-GH': 'GBP',
  'en-GI': 'GBP',
  'en-GM': 'GBP',
  'en-GU': 'GBP',
  'en-GY': 'GBP',
  'en-HK': 'HKD',
  'en-HN': 'HKD',
  'en-IE': 'EUR',
  'en-IL': 'EUR',
  'en-IM': 'EUR',
  'en-IN': 'INR',
  'en-IO': 'INR',
  'en-IT': 'INR',
  'en-JE': 'INR',
  'en-JM': 'INR',
  'en-KE': 'INR',
  'en-KI': 'INR',
  'en-KN': 'INR',
  'en-KY': 'INR',
  'en-LB': 'INR',
  'en-LC': 'INR',
  'en-LK': 'INR',
  'en-LR': 'INR',
  'en-LS': 'INR',
  'en-MG': 'INR',
  'en-MH': 'INR',
  'en-MP': 'INR',
  'en-MS': 'INR',
  'en-MT': 'INR',
  'en-MU': 'INR',
  'en-MW': 'INR',
  'en-MY': 'INR',
  'en-NA': 'INR',
  'en-NF': 'INR',
  'en-NG': 'NGN',
  'en-NL': 'NGN',
  'en-NR': 'NGN',
  'en-NU': 'NGN',
  'en-NZ': 'NZD',
  'en-PA': 'NZD',
  'en-PG': 'NZD',
  'en-PH': 'PHP',
  'en-PI': 'PHP',
  'en-PK': 'PHP',
  'en-PM': 'PHP',
  'en-PN': 'PHP',
  'en-PR': 'PHP',
  'en-PW': 'PHP',
  'en-RW': 'PHP',
  'en-SB': 'PHP',
  'en-SC': 'PHP',
  'en-SG': 'SGD',
  'en-SH': 'SGD',
  'en-SL': 'SGD',
  'en-SS': 'SGD',
  'en-SZ': 'SGD',
  'en-TA': 'SGD',
  'en-TC': 'SGD',
  'en-TK': 'SGD',
  'en-TO': 'SGD',
  'en-TT': 'SGD',
  'en-TV': 'SGD',
  'en-TZ': 'SGD',
  'en-UD': 'SGD',
  'en-UG': 'SGD',
  'en-UM': 'SGD',
  'en-US': 'USD',
  'en-VC': 'USD',
  'en-VG': 'USD',
  'en-VI': 'USD',
  'en-VU': 'USD',
  'en-WS': 'USD',
  'en-ZA': 'ZAR',
  'en-ZM': 'ZAR',
  'en-ZW': 'ZWD',
  'eo-EO': 'ZWD',
  'eo-SM': 'ZWD',
  'es-AD': 'ZWD',
  'es-AR': 'ARS',
  'es-BO': 'BOB',
  'es-BZ': 'BOB',
  'es-CL': 'CLP',
  'es-CO': 'COP',
  'es-CR': 'CRC',
  'es-CU': 'CRC',
  'es-DE': 'CRC',
  'es-DO': 'DOP',
  'es-EA': 'DOP',
  'es-EC': 'USD',
  'es-ES': 'EUR',
  'es-GQ': 'EUR',
  'es-GT': 'GTQ',
  'es-HN': 'HNL',
  'es-IC': 'HNL',
  'es-LA': 'HNL',
  'es-MA': 'HNL',
  'es-MX': 'MXN',
  'es-NI': 'NIO',
  'es-PA': 'PAB',
  'es-PE': 'PEN',
  'es-PH': 'PEN',
  'es-PR': 'USD',
  'es-PY': 'PYG',
  'es-SV': 'SVC',
  'es-TT': 'SVC',
  'es-US': 'USD',
  'es-UY': 'UYU',
  'es-VE': 'VEB',
  'et-EE': 'EEK',
  'et-FI': 'EEK',
  'eu-ES': 'EUR',
  'eu-FR': 'EUR',
  'fa-AE': 'EUR',
  'fa-AF': 'EUR',
  'fa-IQ': 'EUR',
  'fa-IR': 'IRR',
  'fa-OM': 'IRR',
  'fa-PK': 'IRR',
  'fa-QA': 'IRR',
  'fa-TJ': 'IRR',
  'fb-LT': 'IRR',
  'ff-GN': 'IRR',
  'ff-MR': 'IRR',
  'ff-SN': 'IRR',
  'fi-FI': 'EUR',
  'fi-RU': 'EUR',
  'fi-SE': 'EUR',
  'fj-FJ': 'EUR',
  'fo-FO': 'DKK',
  'fr-AD': 'DKK',
  'fr-BE': 'EUR',
  'fr-BF': 'EUR',
  'fr-BI': 'EUR',
  'fr-BJ': 'EUR',
  'fr-BL': 'EUR',
  'fr-CA': 'CAD',
  'fr-CD': 'CAD',
  'fr-CF': 'CAD',
  'fr-CG': 'CAD',
  'fr-CH': 'CHF',
  'fr-CI': 'CHF',
  'fr-CM': 'CHF',
  'fr-CP': 'CHF',
  'fr-DJ': 'CHF',
  'fr-DZ': 'CHF',
  'fr-FR': 'EUR',
  'fr-GA': 'EUR',
  'fr-GB': 'EUR',
  'fr-GF': 'EUR',
  'fr-GN': 'EUR',
  'fr-GP': 'EUR',
  'fr-GQ': 'EUR',
  'fr-HT': 'EUR',
  'fr-IT': 'EUR',
  'fr-KM': 'EUR',
  'fr-LB': 'EUR',
  'fr-LU': 'EUR',
  'fr-MA': 'EUR',
  'fr-MC': 'EUR',
  'fr-MF': 'EUR',
  'fr-MG': 'EUR',
  'fr-ML': 'EUR',
  'fr-MQ': 'EUR',
  'fr-MR': 'EUR',
  'fr-MU': 'EUR',
  'fr-NC': 'EUR',
  'fr-NE': 'EUR',
  'fr-PF': 'EUR',
  'fr-PM': 'EUR',
  'fr-RE': 'EUR',
  'fr-RW': 'EUR',
  'fr-SC': 'EUR',
  'fr-SN': 'EUR',
  'fr-SY': 'EUR',
  'fr-TD': 'EUR',
  'fr-TF': 'EUR',
  'fr-TG': 'EUR',
  'fr-TN': 'EUR',
  'fr-US': 'EUR',
  'fr-VU': 'EUR',
  'fr-WF': 'EUR',
  'fr-YT': 'EUR',
  'fy-NL': 'EUR',
  'ga-GB': 'EUR',
  'ga-IE': 'EUR',
  'gd-GB': 'GBP',
  'gl-ES': 'EUR',
  'gl-PT': 'EUR',
  'gn-AR': 'EUR',
  'gn-BO': 'EUR',
  'gn-PY': 'EUR',
  'gu-IN': 'INR',
  'gu-KE': 'INR',
  'gv-IM': 'INR',
  'he-IL': 'ILS',
  'hi-FJ': 'ILS',
  'hi-IN': 'INR',
  'hi-NP': 'INR',
  'hi-UG': 'INR',
  'hi-ZA': 'INR',
  'ho-PG': 'INR',
  'hr-AT': 'INR',
  'hr-BA': 'INR',
  'hr-DE': 'INR',
  'hr-HR': 'HRK',
  'hr-HU': 'HRK',
  'hr-IT': 'HRK',
  'ht-HT': 'HTG',
  'hu-AT': 'HTG',
  'hu-HU': 'HUF',
  'hu-IL': 'HUF',
  'hu-RO': 'HUF',
  'hu-RS': 'HUF',
  'hu-SI': 'HUF',
  'hu-SK': 'HUF',
  'hu-UA': 'HUF',
  'hy-AM': 'AMD',
  'hy-CY': 'AMD',
  'hy-GE': 'AMD',
  'hy-IR': 'AMD',
  'hy-LB': 'AMD',
  'hy-SY': 'AMD',
  'hy-TR': 'AMD',
  'ia-SE': 'AMD',
  'id-ID': 'IDR',
  'id-NL': 'IDR',
  'ig-NG': 'NGN',
  'ii-CN': 'NGN',
  'ik-US': 'NGN',
  'is-IS': 'ISK',
  'it-AU': 'ISK',
  'it-BR': 'ISK',
  'it-CA': 'ISK',
  'it-CH': 'CHF',
  'it-DE': 'CHF',
  'it-FR': 'CHF',
  'it-GB': 'CHF',
  'it-HR': 'CHF',
  'it-IT': 'EUR',
  'it-SI': 'EUR',
  'it-SM': 'EUR',
  'it-US': 'EUR',
  'it-VA': 'EUR',
  'iu-GL': 'EUR',
  'ja-BR': 'EUR',
  'ja-JP': 'JPY',
  'jv-ID': 'JPY',
  'jv-MY': 'JPY',
  'ka-GE': 'GEL',
  'ka-IR': 'GEL',
  'ka-TR': 'GEL',
  'kg-CD': 'GEL',
  'ki-KE': 'GEL',
  'kj-NA': 'GEL',
  'kl-DK': 'GEL',
  'kl-GL': 'DKK',
  'km-KH': 'KHR',
  'kn-IN': 'INR',
  'ko-BR': 'INR',
  'ko-CN': 'INR',
  'ko-JP': 'INR',
  'ko-KP': 'INR',
  'ko-KR': 'KRW',
  'ko-US': 'KRW',
  'ks-GB': 'KRW',
  'ks-IN': 'INR',
  'ks-PK': 'INR',
  'ku-TR': 'TRY',
  'kw-GB': 'GBP',
  'la-VA': 'GBP',
  'lb-LU': 'GBP',
  'lg-UG': 'UGX',
  'li-NL': 'EUR',
  'ln-CD': 'EUR',
  'ln-CG': 'EUR',
  'lo-LA': 'LAK',
  'lt-LT': 'LTL',
  'lu-CD': 'LTL',
  'lv-LV': 'LVL',
  'mg-MG': 'MGA',
  'mh-MH': 'MGA',
  'mi-NZ': 'NZD',
  'mk-AL': 'NZD',
  'mk-GR': 'NZD',
  'mk-MK': 'MKD',
  'ml-AE': 'MKD',
  'ml-BH': 'MKD',
  'ml-GB': 'MKD',
  'ml-IL': 'MKD',
  'ml-IN': 'INR',
  'ml-MY': 'INR',
  'ml-QA': 'INR',
  'ml-SG': 'INR',
  'mr-IN': 'INR',
  'ms-BN': 'INR',
  'ms-CC': 'INR',
  'ms-ID': 'INR',
  'ms-MY': 'MYR',
  'ms-SG': 'MYR',
  'mt-MT': 'EUR',
  'my-BD': 'EUR',
  'my-MM': 'MMK',
  'na-NR': 'MMK',
  'nb-NO': 'NOK',
  'nb-SJ': 'NOK',
  'nd-ZW': 'NOK',
  'ne-BT': 'NOK',
  'ne-IN': 'NOK',
  'ne-NP': 'INR',
  'ng-NA': 'INR',
  'nl-AW': 'AWG',
  'nl-BE': 'EUR',
  'nl-DE': 'EUR',
  'nl-FR': 'EUR',
  'nl-NL': 'EUR',
  'nl-SR': 'EUR',
  'nn-NO': 'NOK',
  'nr-ZA': 'ZAR',
  'nv-US': 'ZAR',
  'ny-MW': 'ZAR',
  'ny-MZ': 'ZAR',
  'ny-ZM': 'ZAR',
  'ny-ZW': 'ZAR',
  'oc-FR': 'EUR',
  'om-ET': 'ETB',
  'om-KE': 'KES',
  'om-SO': 'KES',
  'or-IN': 'INR',
  'os-GE': 'INR',
  'pa-IN': 'INR',
  'pl-CZ': 'INR',
  'pl-DE': 'INR',
  'pl-IL': 'INR',
  'pl-PL': 'PLN',
  'pl-RO': 'PLN',
  'pl-SK': 'PLN',
  'pl-UA': 'PLN',
  'ps-AE': 'PLN',
  'ps-AF': 'AFN',
  'ps-IR': 'AFN',
  'ps-PK': 'AFN',
  'pt-AG': 'AFN',
  'pt-AO': 'AFN',
  'pt-BR': 'BRL',
  'pt-CV': 'BRL',
  'pt-FR': 'BRL',
  'pt-GW': 'BRL',
  'pt-MO': 'BRL',
  'pt-MZ': 'BRL',
  'pt-PT': 'EUR',
  'pt-ST': 'EUR',
  'pt-TL': 'EUR',
  'qu-BO': 'EUR',
  'qu-PE': 'EUR',
  'rm-CH': 'EUR',
  'rn-BI': 'EUR',
  'ro-HU': 'EUR',
  'ro-IL': 'EUR',
  'ro-MD': 'EUR',
  'ro-RO': 'RON',
  'ro-RS': 'RON',
  'ro-UA': 'RON',
  'ru-BY': 'RON',
  'ru-CN': 'RON',
  'ru-DE': 'RON',
  'ru-EE': 'RON',
  'ru-FI': 'RON',
  'ru-GE': 'RON',
  'ru-IL': 'RON',
  'ru-KG': 'RON',
  'ru-KZ': 'RON',
  'ru-LT': 'RON',
  'ru-LV': 'RON',
  'ru-MD': 'RON',
  'ru-MN': 'RON',
  'ru-RU': 'RUB',
  'ru-SJ': 'RUB',
  'ru-TM': 'RUB',
  'ru-UA': 'UAH',
  'ru-US': 'UAH',
  'ru-UZ': 'UAH',
  'rw-CD': 'UAH',
  'rw-RW': 'RWF',
  'rw-UG': 'RWF',
  'sa-IN': 'INR',
  'se-FI': 'INR',
  'se-NO': 'NOK',
  'se-SE': 'NOK',
  'sg-CF': 'NOK',
  'si-LK': 'LKR',
  'sk-HU': 'LKR',
  'sk-RS': 'LKR',
  'sk-SK': 'EUR',
  'sl-AT': 'EUR',
  'sl-HU': 'EUR',
  'sl-IT': 'EUR',
  'sl-SI': 'EUR',
  'sm-AS': 'EUR',
  'sm-WS': 'EUR',
  'sn-ZW': 'EUR',
  'so-DJ': 'DJF',
  'so-ET': 'ETB',
  'so-KE': 'ETB',
  'so-SO': 'SOS',
  'sq-AL': 'ALL',
  'sq-GR': 'ALL',
  'sq-ME': 'ALL',
  'sq-MK': 'MKD',
  'sq-RS': 'MKD',
  'sq-TR': 'MKD',
  'sr-RS': 'RSD',
  'ss-LS': 'RSD',
  'ss-SZ': 'RSD',
  'ss-ZA': 'ZAR',
  'st-LS': 'ZAR',
  'st-ZA': 'ZAR',
  'su-ID': 'ZAR',
  'sv-AX': 'ZAR',
  'sv-FI': 'EUR',
  'sv-SE': 'SEK',
  'sw-BI': 'SEK',
  'sw-CD': 'SEK',
  'sw-KE': 'SEK',
  'sw-MZ': 'SEK',
  'sw-SO': 'SEK',
  'sw-TZ': 'SEK',
  'sw-UG': 'SEK',
  'sw-YT': 'SEK',
  'sw-ZA': 'SEK',
  'ta-IN': 'INR',
  'ta-LK': 'INR',
  'ta-MU': 'INR',
  'ta-MY': 'INR',
  'ta-RE': 'INR',
  'ta-SG': 'INR',
  'te-IN': 'INR',
  'th-TH': 'THB',
  'ti-ER': 'ERN',
  'ti-ET': 'ETB',
  'ti-IL': 'ETB',
  'tk-AF': 'ETB',
  'tk-IR': 'ETB',
  'tk-TM': 'TMM',
  'tl-PH': 'PHP',
  'tl-US': 'PHP',
  'tn-BW': 'PHP',
  'tn-NA': 'PHP',
  'tn-ZA': 'ZAR',
  'tn-ZW': 'ZAR',
  'to-TO': 'ZAR',
  'tr-BG': 'ZAR',
  'tr-CY': 'TRY',
  'tr-DE': 'TRY',
  'tr-GR': 'TRY',
  'tr-MK': 'TRY',
  'tr-NL': 'TRY',
  'tr-RO': 'TRY',
  'tr-TR': 'TRY',
  'tr-UA': 'TRY',
  'tr-UZ': 'TRY',
  'ts-MZ': 'TRY',
  'ts-SZ': 'TRY',
  'ts-ZA': 'ZAR',
  'tt-RU': 'RUB',
  'ty-PF': 'RUB',
  'uk-PL': 'RUB',
  'uk-SK': 'RUB',
  'uk-UA': 'UAH',
  'ur-IN': 'UAH',
  'ur-MU': 'UAH',
  'ur-PK': 'PKR',
  've-ZA': 'ZAR',
  've-ZW': 'ZAR',
  'vi-CN': 'ZAR',
  'vi-US': 'ZAR',
  'vi-VN': 'VND',
  'wa-BE': 'EUR',
  'xh-LS': 'EUR',
  'xh-ZA': 'ZAR',
  'yi-UA': 'ZAR',
  'yo-BJ': 'ZAR',
  'yo-NG': 'NGN',
  'za-CN': 'NGN',
  'zh-CN': 'CNY',
  'zh-HK': 'HKD',
  'zh-TW': 'TWD',
  'zu-LS': 'TWD',
  'zu-MW': 'TWD',
  'zu-MZ': 'TWD',
  'zu-SZ': 'TWD',
  'zu-ZA': 'ZAR'
};
const TOAST_CONFIG = new InjectionToken('TOAST_CONFIG', {
  providedIn: 'root',
  factory: () => defaultToastConfig
});
const WINDOW = new InjectionToken('WINDOW', {
  providedIn: 'root',
  factory: () => window
});
function provideWindow() {
  const providers = [{
    provide: WINDOW,
    useValue: window
  }];
  return makeEnvironmentProviders([...providers]);
}
function provideNgxUiKitToast(config) {
  const providers = [{
    provide: TOAST_CONFIG,
    useValue: {
      timeOut: config?.timeOut || defaultToastConfig.timeOut,
      disableTimeOut: config?.disableTimeOut || defaultToastConfig.disableTimeOut,
      placement: config?.placement || defaultToastConfig.placement
    }
  }];
  return makeEnvironmentProviders([...providers]);
}
function provideNgxUiKitTranslationConfig(config) {
  const providers = [{
    provide: TRANSLATION_PATH,
    useValue: config?.path || defaultTranslationPath
  }, {
    provide: TRANSLATION_FORM_ERROR_PREFIX,
    useValue: config?.formErrorPrefix || defaultTranslationFormErrorPrefix
  }, {
    provide: TRANSLOCO_MISSING_HANDLER,
    useFactory: () => new MissingTranslationHandler(config?.missingHandlerFn)
  }];
  return makeEnvironmentProviders([...provideTransloco({
    config: translocoConfig(config?.config || defaultTranslationConfig),
    loader: TranslationHttpLoaderService
  }), ...provideTranslocoLocale({
    defaultCurrency: config?.locale?.defaultCurrency || defaultTranslationCurrency,
    defaultLocale: config?.locale?.defaultLocale || defaultTranslationLocale,
    langToLocaleMapping: config?.locale?.langToLocaleMapping || defaultTranslationLocaleLangConfig,
    localeConfig: config?.locale?.localeConfig || defaultTranslationLocaleConfig,
    localeToCurrencyMapping: config?.locale?.localeToCurrencyMapping || LOCALE_CURRENCY
  }), provideTranslocoMessageformat(config?.messageFormat || defaultTranslationMessageFormatConfig), ...provideTranslocoScope({
    scope: 'nsaScope',
    alias: 'NSA',
    loader
  }), ...providers]);
}
class TooltipService {
  constructor() {
    this._tooltips$ = new BehaviorSubject(null);
    this._activeTooltips$ = new BehaviorSubject([]);
  }
  get tooltips$() {
    return this._tooltips$.asObservable();
  }
  get activeTooltips$() {
    return this._activeTooltips$.asObservable();
  }
  show(id, reference, tooltip, placement, color) {
    // this._componentResolverService.createOverlay();
    //
    // this._overlayService.status$.pipe(
    //   skipWhile(attached => !attached),
    //   tap(() => {
    //     this._tooltips$.next({
    //       id,
    //       data: {
    //         reference,
    //         tooltip,
    //         placement,
    //         color
    //       }
    //     });
    //   })
    // ).subscribe();
    this._tooltips$.next({
      id,
      data: {
        reference,
        tooltip,
        placement,
        color
      }
    });
  }
  hide(id) {
    this._tooltips$.next({
      id
    });
  }
  synchronize(tooltips) {
    this._activeTooltips$.next(tooltips);
  }
  static {
    this.ɵfac = function TooltipService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TooltipService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TooltipService,
      factory: TooltipService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class TooltipDirective {
  constructor() {
    this.placement = 'top';
    this.triggers = 'hover';
    this.color = 'primary';
    this.flip = true;
    this._el = inject(ElementRef);
    this._tooltipService = inject(TooltipService);
    this._id = SharedUtils.generateId('tooltip');
    this._activeTooltipsId = [];
    this._subscription = new Subscription();
  }
  ngOnInit() {
    this._subscription = this._tooltipService.activeTooltips$.pipe(tap(activeTooltips => this._activeTooltipsId = activeTooltips.map(({
      id
    }) => id))).subscribe();
  }
  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
  _onMouseEnter() {
    this.triggers === 'hover' && this._tooltipService.show(this._id, this._el.nativeElement, this.tooltip, this.placement, this.color);
  }
  _onMouseLeave() {
    this.triggers === 'hover' && this._tooltipService.hide(this._id);
  }
  _onClick() {
    if (this.triggers === 'click') {
      !!this._activeTooltipsId.includes(this._id) ? this._tooltipService.hide(this._id) : this._tooltipService.show(this._id, this._el.nativeElement, this.tooltip, this.placement, this.color);
    }
  }
  static {
    this.ɵfac = function TooltipDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TooltipDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TooltipDirective,
      selectors: [["", "nsaTooltip", ""]],
      hostBindings: function TooltipDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mouseenter", function TooltipDirective_mouseenter_HostBindingHandler() {
            return ctx._onMouseEnter();
          })("mouseleave", function TooltipDirective_mouseleave_HostBindingHandler() {
            return ctx._onMouseLeave();
          })("click", function TooltipDirective_click_HostBindingHandler() {
            return ctx._onClick();
          });
        }
      },
      inputs: {
        tooltip: [0, "nsaTooltip", "tooltip"],
        placement: [0, "tooltipPlacement", "placement"],
        triggers: [0, "tooltipTriggers", "triggers"],
        color: [0, "tooltipColor", "color"],
        flip: [0, "tooltipFlip", "flip"]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipDirective, [{
    type: Directive,
    args: [{
      selector: '[nsaTooltip]'
    }]
  }], null, {
    tooltip: [{
      type: Input,
      args: [{
        alias: 'nsaTooltip',
        required: true
      }]
    }],
    placement: [{
      type: Input,
      args: [{
        alias: 'tooltipPlacement'
      }]
    }],
    triggers: [{
      type: Input,
      args: [{
        alias: 'tooltipTriggers'
      }]
    }],
    color: [{
      type: Input,
      args: [{
        alias: 'tooltipColor'
      }]
    }],
    flip: [{
      type: Input,
      args: [{
        alias: 'tooltipFlip'
      }]
    }],
    _onMouseEnter: [{
      type: HostListener,
      args: ['mouseenter']
    }],
    _onMouseLeave: [{
      type: HostListener,
      args: ['mouseleave']
    }],
    _onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();
class TooltipContentComponent {
  constructor() {
    this._cdr = inject(ChangeDetectorRef);
  }
  get isString() {
    return typeof this.tooltipConfig.data.tooltip === 'string';
  }
  get floatingPlacement() {
    switch (this.tooltipConfig.data.placement) {
      case 'top':
      case 'bottom':
        return this.tooltipConfig.data.placement;
      case 'start':
        return 'left';
      case 'end':
        return 'right';
    }
  }
  get finalPlacement() {
    return this._finalPlacement || this.tooltipConfig.data.placement;
  }
  ngAfterViewInit() {
    this._cleanUp = autoUpdate(this.tooltipConfig.data.reference, this.floatingElementRef.nativeElement, () => {
      this._computePosition();
    }, {
      ancestorScroll: false
    });
  }
  ngOnDestroy() {
    this._cleanUp?.();
  }
  _computePosition() {
    const arrowElement = this.floatingElementRef.nativeElement.querySelector('.tooltip-arrow');
    const arrowLength = arrowElement.offsetWidth;
    let middleware = [offset(8), arrow({
      element: arrowElement,
      padding: 4
    }), flip()];
    const config = {
      placement: this.floatingPlacement,
      strategy: 'fixed',
      middleware
    };
    computePosition(this.tooltipConfig.data.reference, this.floatingElementRef.nativeElement, {
      ...config
    }).then(({
      middlewareData,
      x,
      y,
      placement
    }) => {
      if (!!middlewareData.arrow) {
        const arrowPlacement = this._getArrowPlacement(placement);
        const arrowSide = this._getArrowSide(arrowPlacement);
        Object.assign(arrowElement.style, {
          top: '',
          bottom: '',
          left: '',
          right: '',
          [arrowPlacement]: middlewareData.arrow[arrowSide] != null ? `${middlewareData.arrow[arrowSide]}px` : ''
        });
      }
      this._finalPlacement = this._getTooltipPlacement(placement);
      Object.assign(this.floatingElementRef.nativeElement.style, {
        left: `${x}px`,
        top: `${y}px`
      });
      this._cdr.detectChanges();
    });
  }
  _getArrowPlacement(placement) {
    return placement === 'top' || placement === 'bottom' ? 'left' : 'top';
  }
  _getArrowSide(placement) {
    return placement === 'top' ? 'y' : 'x';
  }
  _getTooltipPlacement(placement) {
    switch (placement) {
      case 'top':
      case 'bottom':
        return placement;
      case 'left':
        return 'start';
      case 'right':
        return 'end';
    }
  }
  static {
    this.ɵfac = function TooltipContentComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TooltipContentComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TooltipContentComponent,
      selectors: [["nsa-tooltip-content"]],
      viewQuery: function TooltipContentComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.floatingElementRef = _t.first);
        }
      },
      inputs: {
        tooltipConfig: "tooltipConfig"
      },
      decls: 6,
      vars: 8,
      consts: [["floatingElementRef", ""], [3, "id"], [1, "tooltip-arrow", "arrow", "position-absolute"], [1, "tooltip-inner"], [4, "ngTemplateOutlet"]],
      template: function TooltipContentComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 1, 0);
          i0.ɵɵelement(2, "div", 2);
          i0.ɵɵelementStart(3, "div", 3);
          i0.ɵɵtemplate(4, TooltipContentComponent_Conditional_4_Template, 1, 1)(5, TooltipContentComponent_Conditional_5_Template, 1, 1, "ng-container");
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate4("nsa-tooltip nsa-tooltip-", ctx.tooltipConfig.data.color, " position-fixed bs-tooltip-", ctx.finalPlacement, " in tooltip show tooltip-", ctx.finalPlacement, " ", ctx.finalPlacement, "");
          i0.ɵɵproperty("id", ctx.tooltipConfig.id);
          i0.ɵɵadvance(4);
          i0.ɵɵconditional(ctx.isString ? 4 : 5);
        }
      },
      dependencies: [CommonModule, i1.NgTemplateOutlet],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipContentComponent, [{
    type: Component,
    args: [{
      selector: 'nsa-tooltip-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CommonModule],
      template: "<div\r\n  #floatingElementRef\r\n  [id]=\"tooltipConfig.id\"\r\n  class=\"nsa-tooltip nsa-tooltip-{{tooltipConfig.data.color}} position-fixed bs-tooltip-{{finalPlacement}} in tooltip show tooltip-{{finalPlacement}} {{finalPlacement}}\">\r\n\r\n  <div class=\"tooltip-arrow arrow position-absolute\"></div>\r\n\r\n  <div class=\"tooltip-inner\">\r\n    @if(isString) {\r\n      {{ tooltipConfig.data.tooltip }}\r\n    } @else {\r\n      <ng-container *ngTemplateOutlet=\"tooltipConfig.data.tooltip\"></ng-container>\r\n    }\r\n  </div>\r\n</div>\r\n"
    }]
  }], null, {
    floatingElementRef: [{
      type: ViewChild,
      args: ['floatingElementRef']
    }],
    tooltipConfig: [{
      type: Input,
      args: [{
        required: true
      }]
    }]
  });
})();
class TooltipModule {
  static {
    this.ɵfac = function TooltipModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TooltipModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TooltipModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [TooltipContentComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipModule, [{
    type: NgModule,
    args: [{
      imports: [TooltipDirective, TooltipContentComponent],
      exports: [TooltipDirective, TooltipContentComponent]
    }]
  }], null, null);
})();
class ToastComponent {
  constructor() {
    this.close = new EventEmitter();
  }
  get isInfo() {
    return this.messageConfig.severity === 'info';
  }
  get isSuccess() {
    return this.messageConfig.severity === 'success';
  }
  get isWarning() {
    return this.messageConfig.severity === 'warning';
  }
  get isDanger() {
    return this.messageConfig.severity === 'danger';
  }
  getToastId(id) {
    return SharedUtils.generateId('toast', id);
  }
  onCloseToast() {
    this.close.emit(this.messageConfig.id);
  }
  static {
    this.ɵfac = function ToastComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ToastComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ToastComponent,
      selectors: [["nsa-toast"]],
      inputs: {
        messageConfig: "messageConfig"
      },
      outputs: {
        close: "close"
      },
      decls: 13,
      vars: 21,
      consts: [["role", "alert", "aria-live", "assertive", "aria-atomic", "true", 3, "id"], [1, "nsa-toast-content", "d-flex", "p-3"], [1, "nsa-toast-icon", "fs-4", "m-auto"], [1, "bi", 3, "ngClass"], [1, "nsa-toast-message-content", "ms-3", "d-flex", "flex-column", "gap-1", "w-100"], [1, "nsa-toast-message-title", "fw-bold"], [1, "nsa-toast-message-body"], ["type", "button", "data-bs-dismiss", "toast", 1, "btn-close", 3, "click"]],
      template: function ToastComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2);
          i0.ɵɵelement(3, "i", 3);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "div", 4)(5, "div", 5);
          i0.ɵɵtext(6);
          i0.ɵɵpipe(7, "transloco");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(8, "div", 6);
          i0.ɵɵtext(9);
          i0.ɵɵpipe(10, "transloco");
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(11, "button", 7);
          i0.ɵɵpipe(12, "transloco");
          i0.ɵɵlistener("click", function ToastComponent_Template_button_click_11_listener() {
            return ctx.onCloseToast();
          });
          i0.ɵɵelementEnd()()();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate1("toast show nsa-toast nsa-toast-", ctx.messageConfig.severity, " mb-2");
          i0.ɵɵproperty("id", ctx.getToastId(ctx.messageConfig.id));
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction4(16, _c1, ctx.isInfo, ctx.isSuccess, ctx.isWarning, ctx.isDanger));
          i0.ɵɵadvance(3);
          i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind2(7, 8, ctx.messageConfig.title, ctx.messageConfig.translateParams), " ");
          i0.ɵɵadvance(3);
          i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind2(10, 11, ctx.messageConfig.message, ctx.messageConfig.translateParams), " ");
          i0.ɵɵadvance(2);
          i0.ɵɵattribute("aria-label", i0.ɵɵpipeBind1(12, 14, "NSA.ACTION.CLOSE"));
        }
      },
      dependencies: [CommonModule, i1.NgClass, TranslocoModule, i2.TranslocoPipe, TranslocoLocaleModule],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToastComponent, [{
    type: Component,
    args: [{
      selector: 'nsa-toast',
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CommonModule, TranslocoModule, TranslocoLocaleModule],
      template: "<div\r\n  [id]=\"getToastId(messageConfig.id)\"\r\n  class=\"toast show nsa-toast nsa-toast-{{messageConfig.severity}} mb-2\"\r\n  role=\"alert\"\r\n  aria-live=\"assertive\"\r\n  aria-atomic=\"true\">\r\n  <div\r\n    class=\"nsa-toast-content d-flex p-3\">\r\n    <div class=\"nsa-toast-icon fs-4 m-auto\">\r\n      <i\r\n        class=\"bi\"\r\n        [ngClass]=\"{\r\n        'bi-info-circle': isInfo,\r\n        'bi-check-lg': isSuccess,\r\n        'bi-exclamation-triangle': isWarning,\r\n        'bi-x-circle': isDanger\r\n      }\">\r\n      </i>\r\n    </div>\r\n\r\n    <div\r\n      class=\"nsa-toast-message-content ms-3 d-flex flex-column gap-1 w-100\">\r\n      <div class=\"nsa-toast-message-title fw-bold\">\r\n        {{ messageConfig.title | transloco: messageConfig.translateParams }}\r\n      </div>\r\n\r\n      <div class=\"nsa-toast-message-body\">\r\n        {{ messageConfig.message | transloco: messageConfig.translateParams }}\r\n      </div>\r\n    </div>\r\n\r\n    <button\r\n      type=\"button\"\r\n      class=\"btn-close\"\r\n      data-bs-dismiss=\"toast\"\r\n      [attr.aria-label]=\"'NSA.ACTION.CLOSE' | transloco\"\r\n      (click)=\"onCloseToast()\">\r\n    </button>\r\n  </div>\r\n</div>\r\n\r\n\r\n"
    }]
  }], null, {
    messageConfig: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    close: [{
      type: Output
    }]
  });
})();
class OverlayComponent {
  constructor() {
    this.modals = [];
    this.toastMessages = [];
    this.tooltips = [];
    this._modalData$ = new Subscription();
    this._toastMessages$ = new Subscription();
    this._tooltips$ = new Subscription();
    this._router$ = new Subscription();
    this._toastConfig = inject(TOAST_CONFIG);
    this._document = inject(DOCUMENT);
    this._cdr = inject(ChangeDetectorRef);
    this._router = inject(Router);
    this._modalViewerService = inject(ModalViewerService);
    this._toastService = inject(ToastService);
    this._tooltipService = inject(TooltipService);
    this._bsLocaleService = inject(BsLocaleService);
    this._translationDateService = inject(TranslationDateService);
  }
  get toastConfig() {
    return this._toastConfig;
  }
  ngOnInit() {
    defineLocale('it', itLocale);
    this._bsLocaleService.use('it');
    this._modalData$ = this._modalViewerService.getModalData$().pipe(skipWhile(({
      id
    }) => !id), delay(1), tap(({
      id,
      component
    }) => {
      if (!!component) {
        if (!!this.modals.length) {
          this.modals[this.modals.length - 1].hidden = true;
        }
        this.modals.push({
          id,
          component
        });
      } else {
        this.modals = this.modals.filter(({
          id: modalId
        }) => modalId !== id);
        if (!!this.modals.length) {
          this.modals[this.modals.length - 1].hidden = false;
        }
      }
      if (!!this.modals.length) {
        this._document.body.classList.add('overflow-hidden');
      } else {
        this._document.body.classList.remove('overflow-hidden');
      }
      this._cdr.detectChanges();
    })).subscribe();
    this._toastMessages$ = this._toastService.messages$.pipe(skipWhile(config => !config?.id), delay(1), tap(toastMessage => {
      this.toastMessages.push(toastMessage);
      this._cdr.detectChanges();
      if (!this._toastConfig.disableTimeOut) {
        setTimeout(() => {
          this.onCloseToast(toastMessage.id);
        }, this._toastConfig.timeOut);
      }
    })).subscribe();
    this._tooltips$ = this._tooltipService.tooltips$.pipe(skipWhile(config => !config?.id), delay(1), tap(tooltip => {
      if (!!tooltip?.data) {
        this.tooltips.push(tooltip);
      } else {
        this.tooltips = this.tooltips.filter(({
          id: tooltipId
        }) => tooltip.id !== tooltipId);
      }
      this._tooltipService.synchronize(this.tooltips);
      this._cdr.detectChanges();
    })).subscribe();
    this._router$ = this._router.events.pipe(filter(event => event instanceof NavigationEnd), startWith(this._router), tap(() => {
      this.modals.forEach(({
        id
      }) => this._modalViewerService.close(id));
      this.tooltips = [];
      this._tooltipService.synchronize(this.tooltips);
      this._cdr.detectChanges();
    })).subscribe();
  }
  ngOnDestroy() {
    this._modalData$.unsubscribe();
    this._toastMessages$.unsubscribe();
    this._tooltips$.unsubscribe();
    this._router$.unsubscribe();
  }
  getModalId(id) {
    return SharedUtils.generateId('modal', id);
  }
  onCloseToast(id) {
    this.toastMessages = this.toastMessages.filter(({
      id: toastId
    }) => id !== toastId);
    this._cdr.detectChanges();
  }
  static {
    this.ɵfac = function OverlayComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || OverlayComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: OverlayComponent,
      selectors: [["nsa-overlay"]],
      decls: 9,
      vars: 6,
      consts: [[1, "nsa-overlay-container"], [1, "modal-backdrop", "fade", "show", 3, "ngClass"], ["tabindex", "-1", "aria-modal", "true", "role", "dialog", 1, "modal", "fade", "show", 3, "id", "hidden"], [3, "messageConfig"], [3, "tooltipConfig"], [4, "ngComponentOutlet"], [3, "close", "messageConfig"]],
      template: function OverlayComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵelement(1, "div", 1);
          i0.ɵɵrepeaterCreate(2, OverlayComponent_For_3_Template, 2, 4, "div", 2, _forTrack0);
          i0.ɵɵelementStart(4, "div");
          i0.ɵɵrepeaterCreate(5, OverlayComponent_For_6_Template, 1, 1, "nsa-toast", 3, _forTrack0);
          i0.ɵɵelementEnd();
          i0.ɵɵrepeaterCreate(7, OverlayComponent_For_8_Template, 1, 1, "nsa-tooltip-content", 4, _forTrack0);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(4, _c2, !(ctx.modals == null ? null : ctx.modals.length)));
          i0.ɵɵadvance();
          i0.ɵɵrepeater(ctx.modals);
          i0.ɵɵadvance(2);
          i0.ɵɵclassMapInterpolate1("toast-container nsa-toast-container position-fixed nsa-toast-", ctx.toastConfig.placement, "");
          i0.ɵɵadvance();
          i0.ɵɵrepeater(ctx.toastMessages);
          i0.ɵɵadvance(2);
          i0.ɵɵrepeater(ctx.tooltips);
        }
      },
      dependencies: [CommonModule, i1.NgClass, i1.NgComponentOutlet, ToastComponent, TooltipContentComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OverlayComponent, [{
    type: Component,
    args: [{
      selector: 'nsa-overlay',
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CommonModule, ToastComponent, TooltipContentComponent],
      template: "<div\r\n  class=\"nsa-overlay-container\">\r\n  <div\r\n    class=\"modal-backdrop fade show\"\r\n    [ngClass]=\"{ 'd-none': !modals?.length }\">\r\n  </div>\r\n\r\n  @for(modal of modals; track modal.id) {\r\n    <div\r\n      [id]=\"getModalId(modal.id)\"\r\n      class=\"modal fade show\"\r\n      tabindex=\"-1\"\r\n      [attr.aria-labelledby]=\"getModalId(modal.id) + '-title'\"\r\n      aria-modal=\"true\"\r\n      role=\"dialog\"\r\n      [hidden]=\"modal.hidden\">\r\n\r\n      <ng-container *ngComponentOutlet=\"modal.component\"></ng-container>\r\n\r\n    </div>\r\n  }\r\n\r\n  <div class=\"toast-container nsa-toast-container position-fixed nsa-toast-{{toastConfig.placement}}\">\r\n    @for(toastMessage of toastMessages; track toastMessage.id) {\r\n      <nsa-toast\r\n        [messageConfig]=\"toastMessage\"\r\n        (close)=\"onCloseToast($event)\">\r\n      </nsa-toast>\r\n    }\r\n  </div>\r\n\r\n  @for(tooltip of tooltips; track tooltip.id) {\r\n    <nsa-tooltip-content\r\n      [tooltipConfig]=\"tooltip\">\r\n    </nsa-tooltip-content>\r\n  }\r\n</div>\r\n"
    }]
  }], null, null);
})();
class OverlayService {
  constructor() {
    this._document = inject(DOCUMENT);
    this._appRef = inject(ApplicationRef);
    this._injector = inject(EnvironmentInjector);
    this._status = new BehaviorSubject(false);
    this._created = false;
  }
  get status$() {
    return this._status.asObservable();
  }
  createOverlay() {
    if (!this._created) {
      const ref = createComponent(OverlayComponent, {
        environmentInjector: this._injector
      });
      this._document.body.appendChild(ref.location.nativeElement);
      this._appRef.attachView(ref.hostView);
      this._created = true;
    }
  }
  setAttached() {
    this._status.next(true);
  }
  static {
    this.ɵfac = function OverlayService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || OverlayService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: OverlayService,
      factory: OverlayService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OverlayService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class OverlayModule {
  constructor(_overlayService) {
    this._overlayService = _overlayService;
    this._overlayService.createOverlay();
  }
  static {
    this.ɵfac = function OverlayModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || OverlayModule)(i0.ɵɵinject(OverlayService));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: OverlayModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [OverlayComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OverlayModule, [{
    type: NgModule,
    args: [{
      imports: [OverlayComponent],
      exports: [OverlayComponent]
    }]
  }], () => [{
    type: OverlayService
  }], null);
})();
class NgxUiKitModule {
  static forRoot(config) {
    return {
      ngModule: NgxUiKitModule,
      providers: [{
        provide: WINDOW,
        useValue: window
      }, {
        provide: TOAST_CONFIG,
        useValue: {
          timeOut: config?.toast?.timeOut || defaultToastConfig.timeOut,
          disableTimeOut: config?.toast?.disableTimeOut || defaultToastConfig.disableTimeOut,
          placement: config?.toast?.placement || defaultToastConfig.placement
        }
      }, {
        provide: TRANSLATION_PATH,
        useValue: config?.translation?.path || defaultTranslationPath
      }, {
        provide: TRANSLATION_FORM_ERROR_PREFIX,
        useValue: config?.translation?.formErrorPrefix || defaultTranslationFormErrorPrefix
      }, provideTransloco({
        config: translocoConfig(config?.translation?.config || defaultTranslationConfig),
        loader: TranslationHttpLoaderService
      }), provideTranslocoLocale({
        defaultCurrency: config?.translation?.locale?.defaultCurrency || defaultTranslationCurrency,
        defaultLocale: config?.translation?.locale?.defaultLocale || defaultTranslationLocale,
        langToLocaleMapping: config?.translation?.locale?.langToLocaleMapping || defaultTranslationLocaleLangConfig,
        localeConfig: config?.translation?.locale?.localeConfig || defaultTranslationLocaleConfig,
        localeToCurrencyMapping: config?.translation?.locale?.localeToCurrencyMapping || LOCALE_CURRENCY
      }), provideTranslocoMessageformat(config?.translation?.messageFormat || defaultTranslationMessageFormatConfig), provideTranslocoScope({
        scope: 'nsaScope',
        alias: 'NSA',
        loader
      }), {
        provide: TRANSLOCO_MISSING_HANDLER,
        useFactory: () => new MissingTranslationHandler(config?.translation?.missingHandlerFn)
      }]
    };
  }
  static {
    this.ɵfac = function NgxUiKitModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxUiKitModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxUiKitModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [OverlayModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxUiKitModule, [{
    type: NgModule,
    args: [{
      imports: [OverlayModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NgxUiKitModule, provideNgxUiKitToast, provideNgxUiKitTranslationConfig, provideWindow };
