<nsa-modal *transloco="let transloco">
  <ng-container modal-body>
    <div class="d-flex p-1 align-items-center gap-1">
      <div class="fs-4">
        <i class="bi bi-envelope-at"></i>
      </div>

      <div class="text-center">{{ transloco(data.message) }} <a href="mailto:{{data.email}}">{{ data.email }}</a></div>
    </div>
  </ng-container>
</nsa-modal>
