import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CORE_STATE_KEY, coreReducer } from './core.reducer';
import { CoreEffects } from '@core/store/effects/core.effects';
import { environment } from '@env/environment';

const storeDevtoolsModule = environment.production ? [] : [StoreDevtoolsModule.instrument({
  name: 'NSA nservices',
  maxAge: 25,
  logOnly: environment.production
})];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot({ [CORE_STATE_KEY]: coreReducer }, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictActionSerializability: false,
        strictStateSerializability: false
      }
    }),
    [...storeDevtoolsModule],
    EffectsModule.forRoot([
      CoreEffects
    ])
  ]
})
export class CoreStoreModule { }
