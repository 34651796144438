import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NServicesHttpErrorEnum, NServicesHttpErrorResponse } from '@core/models/custom-http-error.model';
import { IUser } from '@core/models/interfaces/user.interface';
import { WebHttpUrlEncodingCodec } from '@core/models/web-http-url-encoding-codec.model';
import { TokenUtils } from '@shared/utils/token.utils';
import { map, Observable, tap } from 'rxjs';
import { StorageService } from './storage.service';
import { SKIP_TOKEN } from '@core/interceptors/core.interceptor';
import { IToken } from '@core/models/interfaces/token.interface';
import { IResponse } from '@core/models/interfaces/response.interface';
import { ConfigService } from '@core/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _window: Window = inject(Window);
  private _httpClient: HttpClient = inject(HttpClient);
  private _storageService: StorageService = inject(StorageService);
  private _configService: ConfigService = inject(ConfigService);

  private get _issuer(): string {
    return this._configService.config.auth.issuer;
  }

  private get _clientId(): string {
    return this._configService.config.auth.clientId;
  }

  private get _tenantId(): string {
    return this._configService.config.auth.tenantId;
  }

  private get _responseType(): string {
    return this._configService.config.auth.responseType;
  }

  private get _scope(): string {
    return this._configService.config.auth.scope;
  }

  private get _authorizationRedirectUri(): string {
    const nonce: string = TokenUtils.generateNonce();
    const separationChar: string = this.authorizationEndpoint.includes('?') ? '&' : '?';
    const [challenge, verifier] = TokenUtils.generatePKCEChallengeVerifier();

    let url = `${this.authorizationEndpoint}${separationChar}`;

    url += `response_type=${encodeURIComponent(this._responseType)}`;
    url += `&client_id=${encodeURIComponent(this._clientId)}`;
    url += `&state=${encodeURIComponent(nonce)}`;
    url += `&redirect_uri=${encodeURIComponent(this._redirectUri)}`;
    url += `&scope=${encodeURIComponent(this._scope)}`;
    url += `&code_challenge=${challenge}`;
    url += '&code_challenge_method=S256';
    url += `&nonce=${encodeURIComponent(nonce)}`;

    this._storageService.clear();
    this._storageService.setNonce(nonce);
    this._storageService.setPkceVerifier(verifier);

    return url;
  }

  private get _endSessionRedirectUri(): string {
    const params: HttpParams = new HttpParams({ encoder: new WebHttpUrlEncodingCodec() })
      .set('id_token_hint', this._storageService.idToken)
      .set('post_logout_redirect_uri', this._redirectUri);

    const separationChar = this.endSessionEndpoint.includes('?') ? '&' : '?';

    this._storageService.clear();

    return `${this.endSessionEndpoint}${separationChar}${params.toString()}`;
  }

  private get _redirectUri(): string {
    return encodeURI(this._window.location.origin);
  }

  get authorizationEndpoint(): string {
    return `${this._issuer}/${this._tenantId}/oauth2/v2.0/authorize`;
  }

  get tokenEndpoint(): string {
    return `${this._issuer}/${this._tenantId}/oauth2/v2.0/token`;
  }

  get endSessionEndpoint(): string {
    return `${this._issuer}/${this._tenantId}/oauth2/v2.0/logout`;
  }

  get userInfoEndpoint(): string {
    return '/users/info';
  }

  get logoutEndpoint(): string {
    return '/users/logout';
  }

  clearStorage(): void {
    this._storageService.clear();
  }

  generateToken(code: string): Observable<Partial<IToken>> {
    let params: HttpParams = new HttpParams()
      .set('client_id', this._clientId)
      .set('code', code)
      .set('code_verifier', this._storageService.pkceVerifier)
      .set('grant_type', 'authorization_code')
      .set('redirect_uri', this._redirectUri);

    return this._getToken(params);
  }

  refreshToken(): Observable<Partial<IToken>> {
    let params: HttpParams = new HttpParams()
      .set('client_id', this._clientId)
      .set('grant_type', 'refresh_token')
      .set('refresh_token', this._storageService.refreshToken);

    return this._getToken(params);
  }

  authorize(): void {
    this._window.open(this._authorizationRedirectUri, '_self');
  }

  endSession(): void {
    this._window.open(this._endSessionRedirectUri, '_self');
  }

  getUser(): Observable<IUser> {
    return this._httpClient.get<IResponse<IUser>>(this.userInfoEndpoint).pipe(
      map(({ data }) => data)
    );
  }

  logout(): Observable<unknown> {
    return this._httpClient.post<IResponse<unknown>>(this.logoutEndpoint, {}).pipe(
      map(({ data }) => data)
    );
  }

  private _getToken(params: HttpParams) {
    const context: HttpContext = new HttpContext().set(SKIP_TOKEN, true);
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this._httpClient.post<IToken>(this.tokenEndpoint, params, { context, headers }).pipe(
      tap(({ access_token, refresh_token, id_token }) => {
        const tokenParts = id_token.split('.');
        const claimsBase64 = TokenUtils.padBase64(tokenParts[1]);
        const claimsJson = TokenUtils.decodeBase64(claimsBase64);
        const claims = JSON.parse(claimsJson);

        if(claims.nonce !== this._storageService.nonce) throw new NServicesHttpErrorResponse(NServicesHttpErrorEnum.INVALID_NONCE);
        else {
          this._storageService.setAccessToken(access_token);
          this._storageService.setRefreshToken(refresh_token);
          this._storageService.setIdToken(id_token);
        }
      })
    );
  }


}
