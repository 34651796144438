import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import * as CoreActions from '@core/store/actions/core.actions';
import { catchError, exhaustMap, map, of, skipWhile, switchMap, tap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { StorageService } from '@core/services/storage.service';
import { AuthService } from '@core/services/auth.service';
import { TranslocoService } from '@jsverse/transloco';
import { NServicesHttpErrorEnum, NServicesHttpErrorResponse } from '@core/models/custom-http-error.model';
import { ConfigService } from '@core/services/config.service';

@Injectable()
export class CoreEffects {

  private _window: Window = inject(Window);
  private _actions$: Actions = inject(Actions);
  private _configService: ConfigService = inject(ConfigService);
  private _authService: AuthService = inject(AuthService);
  private _storageService: StorageService = inject(StorageService);
  private _translocoService: TranslocoService = inject(TranslocoService);
  private _router: Router = inject(Router);

  initEffect$ = createEffect(() => this._actions$.pipe(
    ofType(ROOT_EFFECTS_INIT),
    switchMap(() => this._configService.configLoaded$),
    skipWhile(configLoaded => !configLoaded),
    map(() => this._translocoService.getActiveLang()),
    tap(language => this._storageService.setLanguage(language)),
    exhaustMap(language => this._translocoService.load(language).pipe(
      map(() => language)
    )),
    map(language => CoreActions.setInitData({
      language,
      idToken: this._storageService.idToken,
      accessToken: this._storageService.accessToken,
      refreshToken: this._storageService.refreshToken
    }))
  ));

  initAppEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CoreActions.setInitData),
      map(() => CoreActions.checkToken())
    )
  );

  reloadApplicationEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CoreActions.reloadApplication),
      tap(() => this._authService.clearStorage()),
      tap(() => this._window.open(this._window.location.origin, '_self'))
    ),
    { dispatch: false }
  );

  authorizeEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CoreActions.authorize),
      tap(() => this._authService.authorize())
    ),
    { dispatch: false }
  );

  checkTokenEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CoreActions.checkToken),
      map(() => new URL(this._window.location.href).searchParams.get('code')),
      map(code => this._storageService.hasToken ? CoreActions.getUser() : (!!code ? CoreActions.generateToken({ code }) : CoreActions.setUser({ user: undefined })))
    )
  );

  redirectToLoginEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CoreActions.redirectToLogin),
      tap(() => this._authService.clearStorage()),
      tap(() => this._router.navigate(['/login'])),
    ),
    { dispatch: false }
  );

  redirectToErrorPageEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CoreActions.redirectToErrorPage),
      tap(() => this._authService.clearStorage()),
      tap(() => this._router.navigate(['/error-page']))
    ),
    { dispatch: false }
  );

  redirectToDashboardEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CoreActions.redirectToDashboard),
      tap(() => this._router.navigate(['']))
    ),
    { dispatch: false }
  );

  generateTokenEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CoreActions.generateToken),
      exhaustMap(({ code }) => this._authService.generateToken(code).pipe(
        map(({ id_token: idToken, access_token: accessToken, refresh_token: refreshToken }) => CoreActions.setToken({ idToken, refreshToken, accessToken })),
        catchError((err: NServicesHttpErrorResponse) => {
          if(!!err.code && err.error === NServicesHttpErrorEnum.INVALID_NONCE) return of(CoreActions.redirectToLogin());
          else return throwError(() => err);
        })
      ))
    )
  );

  setTokenEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CoreActions.setToken),
      map(() => CoreActions.getUser())
    )
  );

  setTokenFailedEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CoreActions.setTokenFailed),
      map(() => CoreActions.redirectToLogin())
    )
  );

  getUserEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CoreActions.getUser),
      exhaustMap(() => this._authService.getUser().pipe(
        map(user => CoreActions.setUser({ user })),
        catchError(err => throwError(() => err)
      ))
    ))
  );

  logoutEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CoreActions.logout),
      switchMap(() => this._authService.logout().pipe(
        catchError(() => of(undefined)),
        map(() => CoreActions.logoutSuccess())
      ))
    )
  );

  logoutSuccessEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CoreActions.logoutSuccess),
      tap(() => this._authService.endSession())
    ),
    { dispatch: false }
  );

}
