import { hash } from 'fast-sha256';

const generateNonce = (): string => {
  /*
   * This alphabet is from:
   * https://tools.ietf.org/html/rfc7636#section-4.1
   *
   * [A-Z] / [a-z] / [0-9] / "-" / "." / "_" / "~"
   */
  const unreserved: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
  let size = 45;
  let id = '';

  const crypto = typeof self === 'undefined' ? null : self.crypto || self['msCrypto'];
  if(crypto) {
    let bytes = new Uint8Array(size);
    crypto.getRandomValues(bytes);

    // Needed for IE
    // if(!bytes.map) {
    //   (bytes as any).map = Array.prototype.map;
    // }

    bytes = bytes.map((x) => unreserved.charCodeAt(x % unreserved.length));
    id = String.fromCharCode.apply(null, bytes);
  } else {
    while(0 < size--) id += unreserved[(Math.random() * unreserved.length) | 0];
  }

  return encodeBase64(id);
};

const padBase64 = (base64: string): string => {
  while(base64.length % 4 !== 0) base64 += '=';
  return base64;
};

const encodeBase64 = (value: string): string => btoa(value).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');

const decodeBase64 = (value: string): string => {
  const base64: string = value.replace(/\-/g, '+').replace(/\_/g, '/');
  return decodeURIComponent(atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
};

const encodeUTF8 = (array: Uint8Array): string => {
  let i, output = [];
  for(i = 0; i < array.length; i++) output.push(String.fromCharCode(array[i]));
  return output.join('');
};

const decodeUTF8 = (value: string): Uint8Array => {
  // if(typeof s !== 'string') throw new TypeError('expected string');
  let i, d = value, output = new Uint8Array(d.length);
  for(i = 0; i < d.length; i++) output[i] = d.charCodeAt(i);
  return output;
};

const generatePKCEChallengeVerifier = (): string[] => {
  const verifier: string = generateNonce();
  const challengeRaw: string = encodeUTF8(hash(decodeUTF8(verifier)));
  const challenge: string = encodeBase64(challengeRaw);

  return [challenge, verifier];
};

export const TokenUtils = {
  generateNonce,
  encodeBase64,
  decodeBase64,
  padBase64,
  encodeUTF8,
  decodeUTF8,
  generatePKCEChallengeVerifier
};
