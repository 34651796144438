<ng-container *transloco="let transloco">
  <div class="row vh-100 w-100 m-0 error-page-container">
    <div class="col nsa-map align-items-center d-flex justify-content-center d-none d-sm-grid"></div>

    <div *ngrxLet="errorCode$ as errorCode" class="col align-items-center d-flex justify-content-center bg-white">
      <div class="card error-page-card border-0">
        <div
          class="card-body">
          <div class="d-flex flex-column">
            <div class="card-text text-center">
              <p>
                <!-- TODO ngSrc -->
                <img src="/assets/img/nsa-logo.svg" class="logo" alt="nesea">
              </p>

              <p class="form-label pt-2">{{ transloco('ERROR_PAGE.TITLE', { errorCode }) }}</p>

              <p>{{ transloco('ERROR_PAGE.SUBTITLE', { errorCode }) }}</p>
            </div>

            <nsa-button
              nsaId="reload-configuration"
              size="sm"
              type="button"
              className="mt-2 w-100"
              [fullWidth]="true"
              (click)="onClick(errorCode)">
              {{ transloco('ERROR_PAGE.ACTION', { errorCode }) }}
            </nsa-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
