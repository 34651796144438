import { HttpErrorResponse } from '@angular/common/http';

export enum NServicesHttpErrorEnum {
  INVALID_NONCE = 'INVALID_NONCE'
}

export class NServicesHttpErrorResponse extends HttpErrorResponse {

  readonly code: string = 'NServicesHttpErrorResponse';

  constructor(error: string) {
    super({ status: 401, error });
  }

}
