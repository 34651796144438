import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgxUiKitModule } from '@nesea/ngx-ui-kit';
import { MainContainerComponent } from './components/main-container/main-container.component';
import { HeaderModule } from '@nesea/ngx-ui-kit/header';
import { SpinnerModule } from '@nesea/ngx-ui-kit/spinner';
import { LetDirective, PushPipe } from '@ngrx/component';
import { CoreStoreModule } from '@core/store/core-store.module';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { coreInterceptor } from '@core/interceptors/core.interceptor';
import { StorageService } from '@core/services/storage.service';
import { BreadcrumbModule } from '@nesea/ngx-ui-kit/breadcrumb';
import { HashMap, TranslocoMissingHandlerData } from '@jsverse/transloco';
import { loadConfigurationFactory } from '@core/factories/load-configuration.factory';
import { ConfigService } from '@core/services/config.service';
import { WINDOW } from '@core/tokens';
import localeIt from '@angular/common/locales/it';

registerLocaleData(localeIt, 'it-IT');

@NgModule({
  declarations: [
    MainContainerComponent
  ],
  exports: [
    MainContainerComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    CoreStoreModule,
    LetDirective,
    PushPipe,
    NgxUiKitModule.forRoot({
      toast: {
        timeOut: 10000,
        disableTimeOut: false,
        placement: 'top-end'
      },
      translation: {
        config: {
          availableLangs: ['it', 'en'],
          defaultLang: 'it',
          reRenderOnLangChange: true,
          prodMode: !isDevMode(),
          missingHandler: {
            allowEmpty: true
          }
        },
        locale: {
          langToLocaleMapping: {
            'it': 'it-IT',
            'en': 'en-US'
          },
          localeConfig: {
            global: {
              date: {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
              }
            }
          }
        },
        messageFormat: {
          locales: ['it-IT', 'en-US']
        },
        missingHandlerFn: (key: string, data: TranslocoMissingHandlerData, params: HashMap) => key
      }
    }),
    HeaderModule,
    SpinnerModule,
    BreadcrumbModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfigurationFactory,
      deps: [
        ConfigService
      ],
      multi: true
    },
    // provideAppInitializer(
    //   loadConfigurationFactory(inject(ConfigService))
    // ),
    {
      provide: WINDOW,
      useValue: window
    },
    StorageService,
    provideHttpClient(
      withInterceptors([coreInterceptor]),
    ),
    {
      provide: LOCALE_ID,
      useValue: 'it-IT'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR'
    }
  ]
})
export class CoreModule {}
