import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractModal, ModalViewerService } from '@nesea/ngx-ui-kit/modal';
import { IModalInput, IModalOutput, ITranslationParams } from '@nesea/ngx-ui-kit/shared';
import { SharedModule } from '@shared/shared.module';

export interface ISupportModalInput extends IModalInput {
  message: string;
  email: string;
  translateParams?: ITranslationParams;
}
export interface ISupportModalOutput extends IModalOutput {}

@Component({
  selector: 'nsf-support-modal',
  templateUrl: './support-modal.component.html',
  styleUrl: './support-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class SupportModalComponent extends AbstractModal<ISupportModalInput, ISupportModalOutput> {

  constructor(
    protected override modalViewerService: ModalViewerService
  ) {
    super(modalViewerService);
  }

  override onInit(): void {}
  override onDestroy(): void {}

}
