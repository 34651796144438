import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractModal, ModalViewerService } from '@nesea/ngx-ui-kit/modal';
import { IModalInput, IModalOutput } from '@nesea/ngx-ui-kit/shared';
import { IUser } from '@core/models/interfaces/user.interface';
import { SharedModule } from '@shared/shared.module';

export interface IProfileModalInput extends IModalInput {
  user?: IUser;
}
export interface IProfileModalOutput extends IModalOutput { }

@Component({
  selector: 'nsf-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrl: './profile-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class ProfileModalComponent extends AbstractModal<IProfileModalInput, IProfileModalOutput> {

  constructor(
    protected override modalViewerService: ModalViewerService
  ) {
    super(modalViewerService);
  }

  override onInit(): void {}
  override onDestroy(): void {}

}
