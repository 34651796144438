import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@features/login/login.component';
import { authGuard } from '@core/guards/auth.guard';
import { ErrorPageComponent } from '@features/error-page/error-page.component';
import { hasPermissionsGuard } from '@core/guards/has-permissions.guard';
import { PermissionEnum } from '@core/enums/permission.enum';
import { sessionUserPermissionsResolver } from '@core/resolvers/session-user-permissions.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./features/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canMatch: [authGuard],
    resolve: {
      userPermissions: sessionUserPermissionsResolver
    }
  },
  {
    path: 'my-skills',
    loadChildren: () =>
      import('./features/my-skills/my-skills.module').then(
        (m) => m.MySkillsModule
      ),
    canMatch: [authGuard, hasPermissionsGuard],
    data: {
      breadcrumb: 'MY_SKILLS.BREADCRUMB',
      permissions: [
        PermissionEnum.MANAGE_MY_SKILLS
      ]
    },
  },
  {
    path: 'my-timesheets',
    loadChildren: () => import('./features/my-timesheets/my-timesheets.module').then(m => m.MyTimesheetsModule),
    canMatch: [authGuard, hasPermissionsGuard],
    data: {
      breadcrumb: 'TIMESHEET.BREADCRUMB',
      permissions: [
        PermissionEnum.MANAGE_MY_TIMESHEETS
      ]
    }
  },
  {
    path: 'my-reservations',
    loadChildren: () =>
      import('./features/my-reservations/my-reservations.module').then(
        (m) => m.MyReservationsModule
      ),
    canMatch: [authGuard, hasPermissionsGuard],
    data: {
      breadcrumb: 'MY_RESERVATIONS.BREADCRUMB',
      permissions: [
        PermissionEnum.MANAGE_MY_RESERVATIONS
      ]
    },
  },
  {
    path: 'my-absences',
    loadChildren: () =>
      import('./features/my-absences/my-absences.module').then(
        (m) => m.MyAbsencesModule
      ),
    canMatch: [authGuard, hasPermissionsGuard],
    data: {
      breadcrumb: 'MY_ABSENCES.BREADCRUMB',
      permissions: [
        PermissionEnum.MANAGE_MY_ABSENCES
      ]
    },
  },
  {
    path: 'management-features',
    loadChildren: () =>
      import('./features/management-features/management-features.module').then(
        (m) => m.ManagementFeaturesModule
      ),
    canMatch: [authGuard, hasPermissionsGuard],
    data: {
      breadcrumb: 'MANAGEMENT_FEATURES.BREADCRUMB',
      atLeastPermissions: [
        PermissionEnum.MANAGE_ROLE_PERMISSIONS,
        PermissionEnum.VIEW_ABSENCES,
        PermissionEnum.VIEW_MAPPING_SKILLS,
        PermissionEnum.VIEW_ORDER_ACTIVATIONS,
        PermissionEnum.VIEW_OVERTIMES,
        PermissionEnum.VIEW_RESERVATIONS,
        PermissionEnum.VIEW_SKILLS_REGISTRY,
        PermissionEnum.VIEW_TIMESHEETS,
        PermissionEnum.VIEW_USERS,
        PermissionEnum.MANAGE_FUNDED_TIMESHEETS
      ]
    }
  },
  {
    path: 'my-overtimes',
    loadChildren: () => import('./features/my-overtimes/my-overtimes.module').then(m => m.MyOvertimesModule),
    canMatch: [authGuard, hasPermissionsGuard],
    data: {
      breadcrumb: 'MY_OVERTIMES.BREADCRUMB',
      permissions: [
        PermissionEnum.MANAGE_MY_OVERTIMES
      ]
    }
  },
  // TODO: guard
  {
    path: 'login',
    component: LoginComponent
  },
  // TODO: guard
  {
    path: 'error-page',
    component: ErrorPageComponent
  },
  {
    path: '**',
    redirectTo: 'error-page'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
