<ng-container *transloco="let transloco">
  <div class="row vh-100 w-100 m-0 login-container">
      <div class="col nsa-map align-items-center d-flex justify-content-center d-none d-sm-grid"></div>

      <div class="col align-items-center d-flex justify-content-center bg-white">
        <div class="card login-card border-0">
          <div
            class="card-body">
            <div class="d-flex flex-column">
              <p class="card-text text-center">
                <!-- TODO ngSrc -->
                <img src="/assets/img/nsa-logo.svg" class="logo" alt="nesea">
              </p>

              <nsa-button
                nsaId="submit-login"
                size="sm"
                type="button"
                className="mt-2 w-100"
                [fullWidth]="true"
                (click)="onSubmit()">
                {{ transloco('ACTION.LOGIN') }}
              </nsa-button>
            </div>
          </div>
        </div>
      </div>
  </div>
</ng-container>
