import * as i0 from '@angular/core';
import { Component, Input, NgModule } from '@angular/core';
import { Subscription, filter, distinctUntilChanged } from 'rxjs';
import * as i1 from '@angular/router';
import { NavigationEnd, RouterModule } from '@angular/router';
import * as i3 from '@jsverse/transloco';
import { translate } from '@jsverse/transloco';
import * as i2 from '@angular/common';
import { SharedModule } from '@nesea/ngx-ui-kit/shared';
const _c0 = a0 => ({
  "active": a0
});
function BreadcrumbComponent_Conditional_0_nav_0_For_3_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 4);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r1 = i0.ɵɵnextContext().$implicit;
    const transloco_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("routerLink", item_r1.url);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(transloco_r2(item_r1.label, item_r1.translateParams));
  }
}
function BreadcrumbComponent_Conditional_0_nav_0_For_3_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r2 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r2.getLabel(item_r1), " ");
  }
}
function BreadcrumbComponent_Conditional_0_nav_0_For_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 3);
    i0.ɵɵtemplate(1, BreadcrumbComponent_Conditional_0_nav_0_For_3_Conditional_1_Template, 2, 2, "a", 4)(2, BreadcrumbComponent_Conditional_0_nav_0_For_3_Conditional_2_Template, 2, 1, "span");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ɵ$index_8_r4 = ctx.$index;
    const ɵ$count_8_r5 = ctx.$count;
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(2, _c0, ɵ$index_8_r4 === ɵ$count_8_r5 - 1));
    i0.ɵɵadvance();
    i0.ɵɵconditional(!(ɵ$index_8_r4 === ɵ$count_8_r5 - 1) ? 1 : 2);
  }
}
function BreadcrumbComponent_Conditional_0_nav_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "nav", 0)(1, "ol", 2);
    i0.ɵɵrepeaterCreate(2, BreadcrumbComponent_Conditional_0_nav_0_For_3_Template, 3, 4, "li", 3, i0.ɵɵrepeaterTrackByIndex);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵrepeater(ctx_r2.items);
  }
}
function BreadcrumbComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, BreadcrumbComponent_Conditional_0_nav_0_Template, 4, 0, "nav", 1);
  }
}
class BreadcrumbComponent {
  constructor(_activatedRoute, _router, _cd) {
    this._activatedRoute = _activatedRoute;
    this._router = _router;
    this._cd = _cd;
    this.items = [];
    this._subs = new Subscription();
  }
  ngOnInit() {
    this._subs.add(this._router.events.pipe(filter(event => event instanceof NavigationEnd), distinctUntilChanged()).subscribe(event => {
      const breadcrumbs = !!this.homePage ? [this.homePage] : [];
      this.items = this.buildBreadCrumb(this._activatedRoute.root, breadcrumbs, '');
      this._cd.detectChanges();
    }));
  }
  ngOnDestroy() {
    this._subs.unsubscribe();
  }
  buildBreadCrumb(route, breadcrumbs = [], urlIn = '') {
    // If no routeConfig is avalailable we are on the root path
    const breadcrumbLabel = route.routeConfig?.data?.breadcrumb || '';
    const breadcrumbFn = route.routeConfig?.data?.breadcrumbFn || undefined;
    const translateParams = route.routeConfig?.data?.translateParams || {};
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';
    // If the route is dynamic route such as ':id', remove it
    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.startsWith(':');
    if (isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
    }
    // In the routeConfig the complete path is not available,
    // so we rebuild it each time
    const url = path ? `${urlIn}/${path}` : urlIn;
    const breadcrumb = {
      label: breadcrumbLabel,
      translateParams,
      url,
      getLabel: breadcrumbFn,
      data: route.snapshot?.data
    };
    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label || breadcrumb.getLabel ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
    if (route.firstChild) {
      // If we are not on our current path yet,
      // there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, newBreadcrumbs, url);
    }
    return newBreadcrumbs;
  }
  getLabel(item) {
    if (!!item.getLabel) {
      return item.getLabel(item.data);
    } else {
      return translate(item.label, item.translateParams);
    }
  }
  static {
    this.ɵfac = function BreadcrumbComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BreadcrumbComponent)(i0.ɵɵdirectiveInject(i1.ActivatedRoute), i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BreadcrumbComponent,
      selectors: [["nsa-breadcrumb"]],
      inputs: {
        homePage: "homePage"
      },
      decls: 1,
      vars: 1,
      consts: [["aria-label", "breadcrumb"], ["aria-label", "breadcrumb", 4, "transloco"], [1, "breadcrumb"], [1, "breadcrumb-item", 3, "ngClass"], [3, "routerLink"]],
      template: function BreadcrumbComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, BreadcrumbComponent_Conditional_0_Template, 1, 0, "nav", 0);
        }
        if (rf & 2) {
          i0.ɵɵconditional((ctx.items == null ? null : ctx.items.length) > 1 ? 0 : -1);
        }
      },
      dependencies: [i2.NgClass, i3.TranslocoDirective, i1.RouterLink]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BreadcrumbComponent, [{
    type: Component,
    args: [{
      selector: 'nsa-breadcrumb',
      template: "@if(items?.length > 1) {\r\n  <nav aria-label=\"breadcrumb\" *transloco=\"let transloco\">\r\n    <ol class=\"breadcrumb\">\r\n      @for(item of items; track $index; let last = $last){\r\n        <li class=\"breadcrumb-item\" [ngClass]=\"{'active': last}\">\r\n          @if(!last) {\r\n            <a [routerLink]=\"item.url\">{{ transloco(item.label, item.translateParams) }}</a>\r\n          } @else {\r\n            <span>\r\n              {{ getLabel(item) }}\r\n            </span>\r\n          }\r\n        </li>\r\n      }\r\n    </ol>\r\n  </nav>\r\n}\r\n"
    }]
  }], () => [{
    type: i1.ActivatedRoute
  }, {
    type: i1.Router
  }, {
    type: i0.ChangeDetectorRef
  }], {
    homePage: [{
      type: Input
    }]
  });
})();
class BreadcrumbModule {
  static {
    this.ɵfac = function BreadcrumbModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BreadcrumbModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: BreadcrumbModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [SharedModule, RouterModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BreadcrumbModule, [{
    type: NgModule,
    args: [{
      declarations: [BreadcrumbComponent],
      imports: [SharedModule, RouterModule],
      exports: [BreadcrumbComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BreadcrumbComponent, BreadcrumbModule };
